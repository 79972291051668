import React, { useContext, useEffect, useState } from "react";
import { cacheBuster } from "./config";
import Header from "./Header";
import Footer from "./Footer";
import { Helmet } from "react-helmet";
import { DropdownContext } from "./DropdownContext";

function NewsChannels() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const dropdownContext = useContext(DropdownContext);

  if (!dropdownContext) {
    throw new Error("DropdownContext must be used within DropdownProvider");
  }

  const { selectedOption } = dropdownContext;

  // Add new logo name variable here at the end after "zee24ghanta", For eg: "newlogoname",
  const [tabs] = useState([
    "abpananda",
    "news18bangla",
    "calcuttanews",
    "tv9bangla",
    "republicbangla",
    "kolkatatv",
    "zee24ghanta",
  ]);

  const [selectedBanglaTab, setSelectedBanglaTab] = useState<string>(
    () => localStorage.getItem("selectedBanglaTab") || "abpananda"
  );

  // Handle tab selection
  const handleTabClick = (channelKey: string) => {
    setSelectedBanglaTab(channelKey);
    localStorage.setItem("selectedBanglaTab", channelKey);
  };

  useEffect(() => {
    const savedTab = localStorage.getItem("selectedBanglaTab");
    if (savedTab) {
      setSelectedBanglaTab(savedTab);
    }
  }, []);

  const [visibleTabs, setVisibleTabs] = useState(tabs);
  // Add channel name in "" which you want to hide with comma separated. For eg: const hiddenTabs: any = ["cnbctv18", "cnnnews18"];
  const hiddenTabs: any = [""];

  useEffect(() => {
    const updatedVisibleTabs = visibleTabs.filter(
      (tab) => !hiddenTabs.includes(tab)
    );
    setVisibleTabs(updatedVisibleTabs);

    if (hiddenTabs.includes(selectedBanglaTab)) {
      const nextVisibleTab = updatedVisibleTabs[0];
      if (nextVisibleTab) {
        setSelectedBanglaTab(nextVisibleTab);
      }
    }
  }, []);

  /*****  To add new logo add this line with new logo name start ******/
  const abpananda = `/assets/channel-logos/abp-ananda.png?v=${cacheBuster}`;
  /*****  To add new logo add this line with new logo name end ******/
  const news18bangla = `/assets/channel-logos/news18-bangla.png?v=${cacheBuster}`;
  const calcuttanews = `/assets/channel-logos/calcutta-news.png?v=${cacheBuster}`;
  const tv9bangla = `/assets/channel-logos/tv9-bangla.png?v=${cacheBuster}`;
  const republicbangla = `/assets/channel-logos/republic-bangla.png?v=${cacheBuster}`;
  const kolkatatv = `/assets/channel-logos/kolkata-tv.png?v=${cacheBuster}`;
  const zee24ghanta = `/assets/channel-logos/zee24-ghanta.png?v=${cacheBuster}`;

  const imagesToPreload = [
    abpananda,
    news18bangla,
    calcuttanews,
    tv9bangla,
    republicbangla,
    kolkatatv,
    zee24ghanta,
  ];

  imagesToPreload.forEach((src) => {
    const img = new Image();
    img.src = src;
  });

  return (
    <>
      <div className="Apps">
        <Helmet>
          <title>
            Bangla News Live | ABP Ananda, News18, Calcutta News, TV9, Republic
          </title>
          <meta
            name="description"
            content="Watch live streaming of ABP Ananda, News18 Bangla, TV9 Bangla, Republic Bangla & more on Bangla-News.live. Stay updated with top Bengali news in West Bengal."
          />
          <meta
            name="keywords"
            content="Bangla news live, Bengali news channels, ABP Ananda live, News18 Bangla live, Calcutta News live, TV9 Bangla live, Republic Bangla live, Kolkata TV live, Zee 24 Ghanta live, Bengali breaking news, West Bengal news, live Bengali news streaming"
          />
        </Helmet>
        <Header />

        {selectedOption === "Bangla" && (
          <>
            <div className="logo-grid">
              <div className="tabs">
                {/******  To add new logo copy paste this div(from line number 115 to 134) and change channel name wherever necessary and change the order start *******/}
                {visibleTabs.includes("abpananda") && (
                  <div
                    className={`tab-item ${
                      selectedBanglaTab === "abpananda" ? "active" : ""
                    } order-1`}
                    onClick={() => handleTabClick("abpananda")}
                  >
                    {/**** When you add new logo change the src={abpananda} to src={newlogoname} in below div start ****/}
                    <div className="image-container">
                      <img
                        height="80"
                        width="80"
                        rel="preload"
                        src={abpananda}
                        alt="ABP Ananda Live"
                      />
                    </div>
                    {/**** When you add new logo change the src={abpananda} to src={newlogoname} in below div end ****/}
                  </div>
                )}
                {/******  To add new logo copy paste this div and change channel name wherever necessary and change the order end *******/}

                {visibleTabs.includes("news18bangla") && (
                  <div
                    className={`tab-item ${
                      selectedBanglaTab === "news18bangla" ? "active" : ""
                    } order-2`}
                    onClick={() => handleTabClick("news18bangla")}
                  >
                    <div className="image-container">
                      <img
                        height="80"
                        width="80"
                        rel="preload"
                        src={news18bangla}
                        alt="News18 Bangla Live"
                      />
                    </div>
                  </div>
                )}

                {visibleTabs.includes("calcuttanews") && (
                  <div
                    className={`tab-item ${
                      selectedBanglaTab === "calcuttanews" ? "active" : ""
                    } order-3`}
                    onClick={() => handleTabClick("calcuttanews")}
                  >
                    <div className="image-container">
                      <img
                        height="80"
                        width="80"
                        rel="preload"
                        src={calcuttanews}
                        alt="Calcutta News Live"
                      />
                    </div>
                  </div>
                )}

                {visibleTabs.includes("tv9bangla") && (
                  <div
                    className={`tab-item ${
                      selectedBanglaTab === "tv9bangla" ? "active" : ""
                    } order-4`}
                    onClick={() => handleTabClick("tv9bangla")}
                  >
                    <div className="image-container">
                      <img
                        height="80"
                        width="80"
                        rel="preload"
                        src={tv9bangla}
                        alt="TV9 Bangla Live"
                      />
                    </div>
                  </div>
                )}

                {visibleTabs.includes("republicbangla") && (
                  <div
                    className={`tab-item ${
                      selectedBanglaTab === "republicbangla" ? "active" : ""
                    } order-5`}
                    onClick={() => handleTabClick("republicbangla")}
                  >
                    <div className="image-container">
                      <img
                        height="80"
                        width="80"
                        rel="preload"
                        src={republicbangla}
                        alt="Republic Bangla Live"
                      />
                    </div>
                  </div>
                )}

                {visibleTabs.includes("kolkatatv") && (
                  <div
                    className={`tab-item ${
                      selectedBanglaTab === "kolkatatv" ? "active" : ""
                    } order-6`}
                    onClick={() => handleTabClick("kolkatatv")}
                  >
                    <div className="image-container">
                      <img
                        rel="preload"
                        height="80"
                        width="80"
                        src={kolkatatv}
                        alt="Kolkata TV Live"
                      />
                    </div>
                  </div>
                )}

                {visibleTabs.includes("zee24ghanta") && (
                  <div
                    className={`tab-item ${
                      selectedBanglaTab === "zee24ghanta" ? "active" : ""
                    } order-7`}
                    onClick={() => handleTabClick("zee24ghanta")}
                  >
                    <div className="image-container">
                      <img
                        height="80"
                        width="80"
                        rel="preload"
                        src={zee24ghanta}
                        alt="Zee 24 Ghanta Live"
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>

            {/* Display the selected YouTube video  */}

            <div className="video-container">
              {/******  To add new youtube video iframe copy paste this part and change channel name wherever necessary start  *******/}

              {selectedBanglaTab === "abpananda" &&
                visibleTabs.includes("abpananda") && (
                  <>
                    <h1>ABP Ananda Live</h1>
                    <iframe
                      width="100%"
                      height="450px"
                      src={`https://www.youtube.com/embed/PKwLsAu-z10?si=IAaSiKZzADLizclp&autoplay=1&v=${cacheBuster}`}
                      title="ABP Ananda Live"
                      loading="lazy"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    ></iframe>
                  </>
                )}

              {/******  To add new youtube video iframe copy paste this part and change channel name wherever necessary end *******/}

              {selectedBanglaTab === "news18bangla" &&
                visibleTabs.includes("news18bangla") && (
                  <>
                    <h1>News18 Bangla Live</h1>
                    <iframe
                      width="100%"
                      height="450px"
                      src={`https://www.youtube.com/embed/6Ec7bnQVO44?si=oDKcMB-MMQEOKLZP&autoplay=1&v=${cacheBuster}`}
                      title="News18 Bangla Live"
                      loading="lazy"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    ></iframe>
                  </>
                )}

              {selectedBanglaTab === "calcuttanews" &&
                visibleTabs.includes("calcuttanews") && (
                  <>
                    <h1>Calcutta News Live</h1>
                    <iframe
                      width="100%"
                      height="450px"
                      src={`https://www.youtube.com/embed/_ZcqAXauhIU?si=OGPNKyrPiyofYQgF&autoplay=1&v=${cacheBuster}`}
                      title="Calcutta News Live"
                      loading="lazy"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    ></iframe>
                  </>
                )}

              {selectedBanglaTab === "tv9bangla" &&
                visibleTabs.includes("tv9bangla") && (
                  <>
                    <h1>TV9 Bangla Live</h1>
                    <iframe
                      width="100%"
                      height="450px"
                      src={`https://www.youtube.com/embed/F4nH4PeX_7w?si=yAcJBTAwBRM_-wPu&autoplay=1&v=${cacheBuster}`}
                      title="TV9 Bangla Live"
                      loading="lazy"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    ></iframe>
                  </>
                )}

              {selectedBanglaTab === "republicbangla" &&
                visibleTabs.includes("republicbangla") && (
                  <>
                    <h1>Republic Bangla Live</h1>
                    <iframe
                      width="100%"
                      height="450px"
                      src={`https://www.youtube.com/embed/pivpyRNKZvo?si=lOt9YQakMW_I3LHh&autoplay=1&v=${cacheBuster}`}
                      title="Republic Bangla Live"
                      loading="lazy"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    ></iframe>
                  </>
                )}

              {selectedBanglaTab === "kolkatatv" &&
                visibleTabs.includes("kolkatatv") && (
                  <>
                    <h1>Kolkata TV Live</h1>
                    <iframe
                      width="100%"
                      height="450px"
                      src={`https://www.youtube.com/embed/iYY1R5R5l3k?si=WiYcjlT7sLKjfbK5&autoplay=1&v=${cacheBuster}`}
                      title="Kolkata TV Live"
                      loading="lazy"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    ></iframe>
                  </>
                )}

              {selectedBanglaTab === "zee24ghanta" &&
                visibleTabs.includes("zee24ghanta") && (
                  <>
                    <h1>Zee 24 Ghanta Live</h1>
                    <iframe
                      width="100%"
                      height="450px"
                      src={`https://www.youtube.com/embed/8aApFBmEJV0?si=fxnNT1q8GzTlXxeu&autoplay=1&v=${cacheBuster}`}
                      title="Zee 24 Ghanta Live"
                      loading="lazy"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    ></iframe>
                  </>
                )}
            </div>
          </>
        )}
      </div>

      <div style={{ display: "none" }}>
        <p>Welcome to Bangla News Live – Your Gateway to 24/7 Bengali News </p>
        <p>
          At Bangla News Live, we bring you a one-stop platform to watch your
          favorite Bengali news channels live on YouTube. Stay updated with the
          latest developments in politics, entertainment, business, sports, and
          regional affairs with uninterrupted access to the top Bengali news
          channels. Whether you're at home, at work, or on the go, Bangla News
          Live ensures that you never miss a headline.
        </p>

        <p>
          Watch Your Favorite Bengali News Channels Anytime, Anywhere Our
          platform features live streaming links for the most popular Bengali
          news channels, including OTV Live, News18 Odia Live, Kanak News Live,
          and Nandighosha TV Live. With these channels, you get real-time
          updates and breaking news from across Bengal and beyond. Experience
          high-quality streaming and reliable access to news as it happens.
        </p>

        <p>
          Get Comprehensive Coverage of Regional and National News We understand
          the importance of staying connected to your roots. That’s why we bring
          you channels like OTV Live and Kanak News Live, which focus on
          regional news in Bengali, alongside national coverage. From local
          events to international affairs, Bangla News Live keeps you informed
          and engaged with credible and timely reporting.
        </p>

        <p>
          Stay Informed with News18 Odia and Nandighosha TV Our platform also
          features live links to News18 Odia Live and Nandighosha TV Live,
          offering a unique perspective on regional issues and cultural
          happenings. These channels deliver high-quality content tailored to
          Bengali-speaking audiences, ensuring that you get relevant news in the
          language you understand best.
        </p>

        <p>
          Why Choose Bangla News Live? Bangla News Live is designed to offer
          convenience and reliability. With easy navigation and direct links to
          live YouTube streams, we aim to be your trusted source for Bengali
          news. Bookmark our site today and join thousands of users who rely on
          us for accurate and up-to-date information. Stay connected, stay
          informed, and experience the power of Bengali journalism at your
          fingertips.
        </p>
      </div>
      <Footer />
    </>
  );
}

export default NewsChannels;
